import { Layout } from '@components/layout';
import { StructuredData } from '@components/layout';
import { Offer, PaymentModal, Contact } from '@components/standard';
import React, { useRef } from 'react';

const IndexPage = ({ pageContext, location }) => {
    const { page, productsWithDetails } = pageContext;
    console.log('pp', productsWithDetails);
    const paymentModalRef = useRef();

    console.log(pageContext);

    return (
        <Layout {...page}>
            <Offer products={productsWithDetails} ref={paymentModalRef} />
            <PaymentModal ref={paymentModalRef} />
            <Contact />
        </Layout>
    );
};

export default IndexPage;
